.sidebar {
    height: 100vh;
}

.login-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

label {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    margin-right: 40px;
}

input, select {
    padding: 8px;
    margin-top: 4px;
    width: 100%;
    margin-bottom: 16px;
}

button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.expense-table {
    width: 100%;
    /* Adjust the width as needed */
    border-collapse: collapse;
    margin-top: 20px;
}

.expense-table th,
.expense-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.expense-table th {
    background-color: #f2f2f2;
}

.ps-menu-icon {
    font-size: 22px;
}