.app-container {
  display: flex;
}

.flex-container {
  display: flex;
  flex-direction: column;
}


main {
  flex: 1; /* This will make the main content take up the remaining space */
  padding: 20px; /* Add any padding as needed */
  /* Add any other styles for the main element */
}

